<template>
  <b-overlay
    :show="spinnerShow === true"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Add New User
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Media -->
            <b-media class="mb-2">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  :src="profileImg"
                  :text="'Profile Image'"
                  :variant="`light-info`"
                  size="90px"
                  rounded
                />
              </template>
              <h4 class="mb-1">
                Profile Image
              </h4>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  @click="$refs.refInputEl.click()"
                >
                  <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="inputImageRenderer"
                  >
                  <span class="d-none d-sm-inline">Select Image</span>
                  <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                  />
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  @click="resetImage"
                >
                  <span class="d-none d-sm-inline">Remove</span>
                  <feather-icon
                    icon="TrashIcon"
                    class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-media>

            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
            >
              <b-form-group
                label="Full Name"
                label-for="full-name"
              >
                <b-form-input
                  id="full-name"
                  v-model="userData.fullName"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="John Doe"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Gender -->
            <validation-provider
              #default="validationContext"
              name="Gender"
              rules="required"
            >
              <b-form-group
                label="Gender"
                label-for="gender"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genderOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="gender"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- User Role -->
            <validation-provider
              #default="validationContext"
              name="User Role"
              rules="required"
            >
              <b-form-group
                label="User Role"
                label-for="user-role"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="jobTitle"
              rules="required"
            >
              <b-form-group
                label="Job Title"
                label-for="job-title"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.job_titles"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="jobTitleOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="job-title"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </b-overlay>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BMedia, BAvatar, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BMedia,
    BAvatar,
    vSelect,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    showOptions: {
      type: Array,
      required: true,
    },
    jobTitleOptions: {
      type: Array,
      required: true,
    },
    profileImg: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      spinnerShow: false,
      genderOptions: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Other', value: 'Other' },
      ],
    }
  },
  methods: {
    refetchData() {
      return this.$forceUpdate()
    },

    onSubmit() {
      this.spinnerShow = true
      // eslint-disable-next-line no-undef
      this.$set(this.userData, 'profileImg', this.profileImg)
      const toast = useToast()
      store.dispatch('userStore/addUser', this.userData)
        .then(() => {
          this.spinnerShow = false
          this.emit('refetch-data')
          this.emit('update:is-add-new-user-sidebar-active', false)
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in creating new user',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      this.reset()
    },
    reset() {
      this.userData.value = JSON.parse(JSON.stringify(this.blankUserData))
      this.profileImg = null
    },
    resetImage() {
      this.profileImg = null
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      email: '',
      role: null,
      jobTitle: null,
      shift: null,
      profileImg: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.profileImg = base64
    })

    return {
      userData,
      avatarText,
      refFormObserver,
      getValidationState,
      resetForm,
      inputImageRenderer,
      previewEl,
      refInputEl,
      emit,
      blankUserData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
